import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import map from '../assets/images/travel/sevenpondsmap.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>S+J Travel</title>
            <meta name="description" content="travel" />
        </Helmet>

        <div id="main" className="alt travel">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Getting to Seven Ponds Farm</h1>
                    </header>

                    <h2>Address</h2>
                    <h4>Seven Ponds Road, Summit, NY 12175</h4>

                    <div>
                    <ul>
                    <li><a href="https://goo.gl/maps/ZEjgooboLDN2">Google Maps</a> </li>
                    <li><Link to="/lodging/">Also see here</Link> for more information on taxis, shuttles, and transportation the day of the wedding.</li>
                    </ul>
                    </div>
                    
                    <h3 className="swing">Important Note</h3>

                    <div className="afterSwing"> Your navigation app may suggest you take Spruce Lake Road. <b>Do not do this</b>. As much as we embrace technology and modern route-finding algorithms, this road is typically impassable. Don't be a hero - see the map below.</div>
                    <span className="image main"><img src={map} alt="" /></span>

                    <h2>Flying</h2>
                    <ul>
                   <li> We suggest flying into Albany and renting a car. It’s about an hour drive to Seven Ponds Farm from the Albany International Airport.</li>
                    
                    
<li>You could also fly into Boston (Logan) or any NYC airport (JFK, LGA or Newark).  It’s about a 3.5 hour drive to Seven Ponds from all of these airports.</li>
</ul>
                 </div>
            </section>
        </div>

    </Layout>
)

export default Generic